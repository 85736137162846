import React from 'react'
import Footer from '../components/Footer'
// import Header from '../components/Header'
import Navbar from '../components/Navbar'
import MediaComponent from '../components/MediaComponent'
import Newsletter from '../Newsletter'
const Media = () => {
  return (
    <div>


<Navbar/>
<MediaComponent/>
<Newsletter/>
<Footer />
    </div>
  )
}

export default Media